import "./About.css"
import React from "react";
import { useEffect, useState, useMemo } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { AddCircleOutline, Delete, CopyAllOutlined } from '@mui/icons-material';

import { db } from '../../Firebase/config';
import { arrayRemove, arrayUnion, doc, updateDoc, deleteDoc, query, collection, where, getDocs } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from "react-places-autocomplete";

import { useFetchPills } from "../../Hooks/useFetchPills";




function About({ placesArray, placeIndex, setPlaceIndex }) {


    const [place, setPlace] = useState()
    const [needsUpdate, setNeedsUpdate] = useState(true);

    const [update, setUpdate] = useState(false)
    const [bug, setBug] = useState(0)

    // var places = ["Kafejnīca", "Bistro", "Ēdnīca", "Kulinārija", "Bārs", "Krogs", "Steiku nams", "Sušī", "Jūras veltes", "Picērija", "Maiznīca", "Saldējums", "Sulas bārs", "Gastropubs", "Tējas nams", "Alus darītava", "Vīna darītava", "Tapas bārs"];
    // var mood = ["Rāms", "Atslābinoši", "Iedvesmojoši", "Mierīgi", "Enerģiski", "Nostaļģiski", "Pacilājoši", "Smalki", "Formāli", "Mājīgi", "Omulīgi", "Eleganti", "Dzīvelīgi", "Neformāli", "Bohēmiski"]
    //  var extras = ["WiFi", "Kontakti", "Suņu draudzīgs", "Ratiņkrēsla pieejamība", "Smēķētāju zona"]

    const { pills } = useFetchPills()

    const [places, setPlaces] = useState([])
    const [mood, setMood] = useState([])
    const [extras, setExtras] = useState([])

    const [visibility, setVisibility] = useState(false)

    const [restaurantName, setRestaurantName] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [validVAT, setVAT] = useState('')
    const [emailPublic, setEmailPublic] = useState('')
    const [emailPrivate, setEmailPrivate] = useState('')
    const [phonePublic, setPhonePublic] = useState('')
    const [phonePrivate, setPhonePrivate] = useState('')

    const [address, setAddress] = useState("");
    const [coordinates, setCoordinates] = useState({
        lat: 56.95724923530253,
        lng: 24.10740977685508
    });
    const [placeId, setPlaceId] = useState("")




    const [workingHours, setWorkingHours] = useState([
        { start: -1, end: -1 },
        { start: -1, end: -1 },
        { start: -1, end: -1 },
        { start: -1, end: -1 },
        { start: -1, end: -1 },
        { start: -1, end: -1 },
        { start: -1, end: -1 },
    ])

    const hours = [
        { value: -1, label: 'Brīvs' },
        { value: 0, label: '00:00' },
        { value: 30, label: '00:30' },
        { value: 100, label: '01:00' },
        { value: 130, label: '01:30' },
        { value: 200, label: '02:00' },
        { value: 230, label: '02:30' },
        { value: 300, label: '03:00' },
        { value: 330, label: '03:30' },
        { value: 400, label: '04:00' },
        { value: 430, label: '04:30' },
        { value: 500, label: '05:00' },
        { value: 530, label: '05:30' },
        { value: 600, label: '06:00' },
        { value: 630, label: '06:30' },
        { value: 700, label: '07:00' },
        { value: 730, label: '07:30' },
        { value: 800, label: '08:00' },
        { value: 830, label: '08:30' },
        { value: 900, label: '09:00' },
        { value: 930, label: '09:30' },
        { value: 1000, label: '10:00' },
        { value: 1030, label: '10:30' },
        { value: 1100, label: '11:00' },
        { value: 1130, label: '11:30' },
        { value: 1200, label: '12:00' },
        { value: 1230, label: '12:30' },
        { value: 1300, label: '13:00' },
        { value: 1330, label: '13:30' },
        { value: 1400, label: '14:00' },
        { value: 1430, label: '14:30' },
        { value: 1500, label: '15:00' },
        { value: 1530, label: '15:30' },
        { value: 1600, label: '16:00' },
        { value: 1630, label: '16:30' },
        { value: 1700, label: '17:00' },
        { value: 1730, label: '17:30' },
        { value: 1800, label: '18:00' },
        { value: 1830, label: '18:30' },
        { value: 1900, label: '19:00' },
        { value: 1930, label: '19:30' },
        { value: 2000, label: '20:00' },
        { value: 2030, label: '20:30' },
        { value: 2100, label: '21:00' },
        { value: 2130, label: '21:30' },
        { value: 2200, label: '22:00' },
        { value: 2230, label: '22:30' },
        { value: 2300, label: '23:00' },
        { value: 2330, label: '23:30' },
    ]

    const [about, setAbout] = useState("")

    const [restaurantTypes, setRestaurantTypes] = useState([])
    const [restaurantMood, setRestaurantMood] = useState([])
    const [restaurantExtras, setRestaurantExtras] = useState([])

    const [autoTypes, setAutoTypes] = useState("")
    const [autoMood, setAutoMood] = useState("")
    const [autoExtras, setAutoExtras] = useState("")


    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);




    useEffect(() => {

        console.log("PLACE", place)

        if (place && needsUpdate) {
            setNeedsUpdate(false)

            document.getElementById("toggle").checked = place.visibility;

            setRestaurantName(place.place_name)
            setBusinessName(place.business_name)
            setVAT(place.vat_number)
            setEmailPublic(place.email_public)
            setEmailPrivate(place.email_private)
            setPhonePublic(place.phone_public)
            setPhonePrivate(place.phone_private)
            setWorkingHours(place.working_hours)
            setAddress(place.address)
            setPlaceId(place.address_id)
            setCoordinates({ lat: place.address_lat, lng: place.address_long })
            setRestaurantTypes(place.type)
            setRestaurantMood(place.mood)
            setRestaurantExtras(place.extras)
            setAbout(place.about)
        }


        if (place) {
            setRestaurantTypes(place.type)
            setRestaurantMood(place.mood)
            setRestaurantExtras(place.extras)
        }


    }, [place])


    useEffect(() => {

        if (placesArray) {
            setPlace(place => placesArray[placeIndex])
            setNeedsUpdate(true)
        }

    }, [placeIndex])




    useEffect(() => {

        console.log("CHANGE")
        if (placesArray) {
            setPlace(place => placesArray[placeIndex])
        }

    }, [bug])



    function saveChanges() {
        var d = new Date();
        var seconds = Math.round(d.getTime() / 1000);



        if (place && !needsUpdate) {
            console.log("SAVING WORK")

            updateDoc(doc(db, "places", place.id), {
                place_name: restaurantName,
                business_name: businessName,
                vat_number: validVAT,
                email_public: emailPublic,
                email_private: emailPrivate,
                phone_private: phonePrivate,
                phone_public: phonePublic,
                working_hours: workingHours,
                address: address,
                address_id: placeId,
                address_lat: coordinates.lat,
                address_long: coordinates.lng,
                type: restaurantTypes,
                atmosphere: restaurantMood,
                extras: restaurantExtras,
                about: about,
                updated_at: seconds
            });

        }
    }






    useEffect(() => {

        saveChanges()

    }, [coordinates, placeId, about, restaurantName, businessName, validVAT, emailPrivate, emailPublic, phonePublic, phonePrivate, workingHours, restaurantTypes, restaurantMood, restaurantExtras])



    useEffect(() => {

        if (pills) {

            let tempArray = []

            pills.type.map((item) => {
                tempArray.push(item.name)
            })

            setPlaces(tempArray)
            tempArray = []


            pills.mood.map((item) => {
                tempArray.push(item.name)
            })
            setMood(tempArray)
            tempArray = []


            pills.extras.map((item) => {
                tempArray.push(item.name)
            })
            setExtras(tempArray)

        }
    }, [pills])



    function changeTime(day, position, value) {
        console.log(day, position, value)
        var temp = workingHours

        if (value == '-1') {
            console.log(day, position, value)
            temp[day].start = -1
            temp[day].end = -1

        } else {
            if (position == 0) {
                temp[day].start = value
            } else {
                temp[day].end = value
            }
        }


        setWorkingHours(temp)

        saveChanges()
    }



    function autocomplete(inp, arr) {


        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;

            /*close any already open lists of autocompleted values*/
            closeAllLists();
            if (!val) { return false; }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;

                        addPill(inp.id, inp.value)


                        forceUpdate()
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists();
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            var x = document.getElementById(this.id + "autocomplete-list");
            if (x) x = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (x) x[currentFocus].click();
                }
            }
        });
        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            document.getElementById('places').value = ''
            document.getElementById('mood').value = ''
            document.getElementById('extra').value = ''
            closeAllLists(e.target);
        });
    }





    function addPill(id, value) {



        var d = new Date();
        var seconds = Math.round(d.getTime() / 1000);

        if (place) {

            switch (id) {
                case "places":
                    if (place.type.length < 3) {
                        updateDoc(doc(db, "places", place.id), {
                            updated_at: seconds,
                            type: arrayUnion(value)
                        }).then(() => {
                            let temp = bug
                            temp++
                            setBug(temp)
                        })
                    }
                    break;

                case "mood":
                    if (place.mood.length < 3) {
                        updateDoc(doc(db, "places", place.id), {
                            updated_at: seconds,
                            mood: arrayUnion(value)
                        }).then(() => {
                            let temp = bug
                            temp++
                            setBug(temp)
                        })
                    }
                    break;

                case "extra":

                    updateDoc(doc(db, "places", place.id), {
                        updated_at: seconds,
                        extras: arrayUnion(value)
                    }).then(() => {
                        let temp = bug
                        temp++
                        setBug(temp)
                    })


                    break;
                default:
                    break

            }


            setAutoTypes("")
            setAutoMood("")
            setAutoExtras("")

        }

    }



    function removePill(field, name) {

        var d = new Date();
        var seconds = Math.round(d.getTime() / 1000);


        switch (field) {
            case "type":
                updateDoc(doc(db, "places", place.id), {
                    updated_at: seconds,
                    type: arrayRemove(name)
                }).then(() => {
                    let temp = bug
                    temp++
                    setBug(temp)
                })
                break
            case "mood":
                updateDoc(doc(db, "places", place.id), {
                    updated_at: seconds,
                    mood: arrayRemove(name)
                }).then(() => {
                    let temp = bug
                    temp++
                    setBug(temp)
                })
                break
            case "extra":
                updateDoc(doc(db, "places", place.id), {
                    updated_at: seconds,
                    extras: arrayRemove(name)
                }).then(() => {
                    let temp = bug
                    temp++
                    setBug(temp)
                })
                break
            default:
                break


        }



    }



    function addPillCollection(id) {

        var d = new Date();
        var seconds = Math.round(d.getTime() / 1000);

        let found = false



        if (place && pills) {
            switch (id) {
                case "places":
                    if (place.type.length < 3 && autoTypes) {

                        pills.type.map((item) => {
                            if (item.name == autoTypes) {
                                found = true
                            }
                        })

                        if (!true) {
                            updateDoc(doc(db, "pills", "pills"), {
                                type: arrayUnion({ name: autoTypes, created: seconds, created_by: place.id })
                            });
                        }

                        addPill(id, autoTypes)
                    }
                    break


                case "mood":
                    if (place.mood.length < 3 && autoMood) {

                        pills.mood.map((item) => {
                            if (item.name == autoMood) {
                                found = true
                            }
                        })

                        if (!found) {

                            updateDoc(doc(db, "pills", "pills"), {
                                mood: arrayUnion({ name: autoMood, created: seconds, created_by: place.id })
                            });
                        }

                        addPill(id, autoMood)
                    }
                    break


                case "extra":

                    if (autoExtras != "") {

                        pills.extras.map((item) => {
                            if (item.name == autoExtras) {
                                found = true
                            }
                        })

                        if (!found) {
                            updateDoc(doc(db, "pills", "pills"), {
                                extras: arrayUnion({ name: autoExtras, created: seconds, created_by: place.id })
                            });
                        }

                        addPill(id, autoExtras)
                    }
                    break
                default:
                    break


            }


        }


    }


    const center = useMemo(() => ({ lat: 56.95724923530253, lng: 24.10740977685508 }), []);



    const handleSelect = async value => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        console.log(results[0].place_id)
        console.log(latLng)
        setAddress(value)
        setCoordinates(latLng)
        setPlaceId(results[0].place_id)
    };

    const searchOptions = {
        country: ["lv"],
    }




    async function toggleVisibility(state) {


        if (place) {

            updateDoc(doc(db, "places", place.id), {
                visibility: state,
            }).then(async () => {
                setNeedsUpdate(true)


                const q = query(collection(db, "menu"), where("place_id", "==", place.id));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach(async (item) => {

                    updateDoc(doc(db, "menu", item.id), {
                        visibility: state,
                    })
                })

            })

        }
    }



    async function deletePlace() {

        console.log(place)

        if (place) {

            let UID = sessionStorage.getItem('UID')
            let ID = place.id
            let imagesInterior = place.images_interior
            let imagesMenu = place.images_menu


            setPlaceIndex(0)

            updateDoc(doc(db, "users", UID), {
                places_admin: arrayRemove(ID),
            }).then(async () => {

                await deleteDoc(doc(db, "places", ID)).then(() => {
                    /* let temp = bug
                     temp++
                     setBug(temp)
                     */
                    console.log(placesArray)
                })

                const storage = getStorage();

                if (imagesInterior.length != 0) {
                    imagesInterior.map((item) => {

                        const placeRef = ref(storage, item.path);

                        // Delete the file
                        deleteObject(placeRef).then(() => {
                            // File deleted successfully
                        }).catch((error) => {
                            // Uh-oh, an error occurred!
                        });


                    })
                }



                if (imagesMenu.length != 0) {
                    imagesMenu.map((item) => {

                        const placeRef = ref(storage, item.path);

                        // Delete the file
                        deleteObject(placeRef).then(() => {
                            // File deleted successfully
                        }).catch((error) => {
                            // Uh-oh, an error occurred!
                        });


                    })
                }




                const q = query(collection(db, "menu"), where("place_id", "==", ID));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach(async (item) => {
                    await deleteDoc(doc(db, "menu", item.id));
                })



            })

        }
    }



    return (

        <div className="about">


            {sessionStorage.getItem('userEmail') == "tomassparandjuks@gmail.com" &&
                <>
                    {place && <div onClick={() => { navigator.clipboard.writeText(`maratolliaccesscode-${place.id}`) }} className="accesscode">
                        <p>maratolliaccesscode-{place.id}</p>
                        <CopyAllOutlined />
                    </div>
                    }
                </>
            }


            {sessionStorage.getItem('userEmail') == "gabriels22paulins@gmail.com" &&
                <>
                    {place && <div onClick={() => { navigator.clipboard.writeText(`maratolliaccesscode-${place.id}`) }} className="accesscode">
                        <p>maratolliaccesscode-{place.id}</p>
                        <CopyAllOutlined />
                    </div>
                    }
                </>
            }


            {sessionStorage.getItem('userEmail') == "gabriels22paulins@gmail.com" &&
                <>
                    {place && <div  onClick={() => { navigator.clipboard.writeText(`${place.registration_email}`) }} className="accesscode">
                        <p>{place.registration_email}</p>
                        <CopyAllOutlined />
                    </div>
                    }
                </>
            }

            {sessionStorage.getItem('userEmail') == "tomassparandjuks@gmail.com" &&
                <>
                    {place && <div  onClick={() => { navigator.clipboard.writeText(`${place.registration_email}`) }} className="accesscode">
                        <p>{place.registration_email}</p>
                        <CopyAllOutlined />
                    </div>
                    }
                </>
            }

            {/*}
            {update && <div onClick={() => saveChanges()} className="save-button">
                <h5>Saglabāt</h5>
            </div>
            }
        */}


            <div className="flex-contaner">




                <div style={{ width: "35%" }} className="flex">


                    <div className="text-input">


                        {place &&
                            <div className="toggle">

                                <p>
                                    Publiska
                                </p>

                                <label class="switch">
                                    <input id="toggle" onClick={(e) => toggleVisibility(e.target.checked)} type="checkbox" />
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        }


                        <p>
                            Iestādes nosaukums
                        </p>

                        <input
                            type="text"
                            onChange={(e) => setRestaurantName(e.target.value)}
                            value={restaurantName}
                        />
                    </div>

                    <div className="text-input">
                        <p>
                            Uzņēmuma nosaukums (SIA)
                        </p>

                        <input
                            type="text"
                            onChange={(e) => setBusinessName(e.target.value)}
                            value={businessName}
                        />
                    </div>

                    <div className="text-input">
                        <p>
                            Uzņēmuma reģistrācijas numurs
                        </p>

                        <input
                            type="number"
                            onChange={(e) => setVAT(e.target.value)}
                            value={validVAT}
                        />
                    </div>

                    <div className="text-input">
                        <p>
                            Telefons (privāts)
                        </p>

                        <input
                            type="number"
                            onChange={(e) => setPhonePrivate(e.target.value)}
                            value={phonePrivate}
                        />
                    </div>

                    <div className="text-input">
                        <p>
                            Epasts (privāts)
                        </p>

                        <input
                            type="text"
                            onChange={(e) => setEmailPrivate(e.target.value)}
                            value={emailPrivate}
                        />
                    </div>

                    <div className="text-input">
                        <p>
                            Telefons (publiski pieejams)
                        </p>

                        <input
                            type="number"
                            onChange={(e) => setPhonePublic(e.target.value)}
                            value={phonePublic}
                        />
                    </div>

                    <div className="text-input">
                        <p>
                            Epasts (publiski pieejams)
                        </p>

                        <input
                            type="text"
                            onChange={(e) => setEmailPublic(e.target.value)}
                            value={emailPublic}
                        />
                    </div>




                    <div className="text-input">
                        <p>
                            Darba laiki
                        </p>

                        <div className="working-hours">
                            <div className="day-row">
                                <p>Pirmdiena</p>
                                <p>Otrdiena</p>
                                <p>Trešdiena</p>
                                <p>Ceturtdiena</p>
                                <p>Piektdiena</p>
                                <p>Sestdiena</p>
                                <p>Svētdiena</p>
                            </div>

                            <form onChange={forceUpdate}>
                                {workingHours.map((day, index) => (
                                    <div className="time-row">
                                        <select value={day.start} onChange={(e) => changeTime(index, 0, e.target.value)}>
                                            {hours.map((item) => (
                                                <option value={item.value} label={item.label}>
                                                </option>
                                            ))}
                                        </select>
                                        <h5>-</h5>
                                        <select value={day.end} onChange={(e) => changeTime(index, 1, e.target.value)}>
                                            {hours.map((item) => (
                                                <option value={item.value} label={item.label}>
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                ))}


                            </form>
                        </div>
                    </div>



                </div>


                <div style={{ width: "60%" }} className="flex">



                    <div style={{ width: '100%' }} className="text-input">
                        <p>
                            Neliels apraksts par Jums (rekomendējam)
                        </p>


                        <textarea
                            type="textarea"
                            onChange={(e) => setAbout(e.target.value)}
                            value={about}
                        />
                    </div>





                    <PlacesAutocomplete
                        searchOptions={searchOptions}
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}


                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div className="text-input right">


                                <input className="maps-autocomplite" {...getInputProps({ placeholder: "Iestādes adrese..." })} />

                                <div>
                                    {loading ? <div>...loading</div> : null}

                                    {suggestions.map(suggestion => {
                                        const style = {
                                            backgroundColor: suggestion.active ? "#755D8B" : "#fff",
                                            color: suggestion.active ? "#fff" : "black",
                                            padding: "0.5rem",
                                            cursor: "pointer",
                                            marginTop: "10px",
                                            borderRadius: "10px"
                                        };

                                        return (
                                            <div  {...getSuggestionItemProps(suggestion, { style })}>
                                                {suggestion.description}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>

                    <GoogleMap zoom={17} center={{ lat: coordinates.lat, lng: coordinates.lng }} mapContainerClassName="map-container">
                        <MarkerF position={{ lat: coordinates.lat, lng: coordinates.lng }} />
                    </GoogleMap>





                    <div style={{ marginBottom: "60px" }} className="text-input right">
                        <p>
                            Iestādes veids (līdz 3 veidiem)
                        </p>

                        <div className="pill-row">

                            <div className="pill-sellector">
                                {pills && pills.type && pills.type.map((item) => (
                                    <div onClick={() => addPill("places", item.name)} className="pill">
                                        <p>{item.name}</p>
                                    </div>
                                ))}
                            </div>


                            <div style={{ marginBottom: "20px" }}>

                                <div style={{ display: "flex" }}>
                                    <div className="autocomplete">
                                        <input
                                            onChange={(e) => { autocomplete(document.getElementById("places"), places); setAutoTypes(e.target.value) }} id="places" type="text" name="placesTypes" />
                                    </div>

                                    <div onClick={() => addPillCollection("places")} className="add-button">
                                        <AddCircleOutline className="icon" />
                                    </div>
                                </div>

                                <div style={{ width: "250px", textAlign: "center" }}>
                                    <p style={{ fontSize: "10px" }}>Ja neatrodat sev piemērotu veidu, tad ierakstiet jūsuprāt piemērotāko un spiediet “Pievienot”</p>
                                </div>
                            </div>

                            <div className="pill-bundle">

                                {restaurantTypes.map((item, index) => (
                                    <div className="pill">
                                        <p>{item}</p>
                                        <AddCircleOutline onClick={() => removePill("type", item)} className="icon" />
                                    </div>
                                ))}
                            </div>

                        </div>



                    </div>


                    <div style={{ marginBottom: "60px" }} className="text-input right">
                        <p>
                            Atmosfēra (līdz 3 veidiem)
                        </p>

                        <div className="pill-row">

                            <div className="pill-sellector">
                                {pills && pills.mood && pills.mood.map((item) => (
                                    <div onClick={() => addPill("mood", item.name)} className="pill">
                                        <p>{item.name}</p>
                                    </div>
                                ))}
                            </div>

                            <div style={{ marginBottom: "20px" }}>
                                <div style={{ display: "flex" }}>
                                    <div className="autocomplete">
                                        <input className="" onChange={(e) => { autocomplete(document.getElementById("mood"), mood); setAutoMood(e.target.value) }} id="mood" type="text" name="myCountry" />
                                    </div>

                                    <div onClick={() => addPillCollection("mood")} className="add-button">
                                        <AddCircleOutline className="icon" />
                                    </div>
                                </div>

                                <div style={{ width: "250px", textAlign: "center" }}>
                                    <p style={{ fontSize: "10px" }}>Ja neatrodat sev piemērotu atmosfēras veidu, tad ierakstiet jūsuprāt piemēroto un spiediet “Pievienot”</p>
                                </div>
                            </div>

                            <div className="pill-bundle">

                                {restaurantMood.map((item, index) => (
                                    <div className="pill">
                                        <p>{item}</p>
                                        <AddCircleOutline onClick={() => removePill("mood", item)} className="icon" />
                                    </div>
                                ))}
                            </div>

                        </div>

                    </div>



                    <div className="text-input right">
                        <p>
                            Ekstras
                        </p>

                        <div className="pill-row">

                            <div className="pill-sellector">
                                {pills && pills.extras && pills.extras.map((item) => (
                                    <div onClick={() => addPill("extra", item.name)} className="pill">
                                        <p>{item.name}</p>
                                    </div>
                                ))}
                            </div>


                            <div style={{ marginBottom: "20px" }}>
                                <div style={{ display: "flex" }}>
                                    <div className="autocomplete">
                                        <input className="" onChange={(e) => { autocomplete(document.getElementById("extra"), extras); setAutoExtras(e.target.value) }} id="extra" type="text" name="myCountry" />
                                    </div>

                                    <div onClick={() => addPillCollection("extra")} className="add-button">
                                        <AddCircleOutline className="icon" />
                                    </div>
                                </div>

                                <div style={{ width: "250px", textAlign: "center" }}>
                                    <p style={{ fontSize: "10px" }}>Ja neatrodat to ko meklējat, tad ierakstiet jūsu piedāvāto un spiediet “Pievienot”</p>
                                </div>
                            </div>

                            <div className="pill-bundle">

                                {restaurantExtras.map((item, index) => (
                                    <div className="pill">
                                        <p>{item}</p>
                                        <AddCircleOutline onClick={() => removePill("extra", item)} className="icon" />
                                    </div>
                                ))}
                            </div>

                        </div>

                    </div>




                </div>

            </div>


            {placesArray && placesArray.length > 1 && place && place.admins.length == 2 &&
                <Delete onClick={() => deletePlace()} className="delete-place" />
            }

        </div >
    )
}

export default About;