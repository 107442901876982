import "./Images.css"
import { useEffect, useState, useCallback, updateState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { storage, db } from "../../Firebase/config";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import imageCompression from 'browser-image-compression'


import { Delete } from '@mui/icons-material';

import Banner from "../../Components/Banner/Banner";





function Images({ place }) {

    const [location, setLocation] = useState("interior")

    const [banner, setBanner] = useState(false)
    const [message, setMessage] = useState("")
    const [type, setType] = useState("")

    function previewImages(loc) {

        var previewContainer = document.getElementById("preview-container");

        if (loc == 0) {
            var fileInput = document.getElementById("file-input");
        } else {
            var fileInput = document.getElementById("file-input-menu");
        }

        var files = fileInput.files;

        if (files.length > 0) {
            previewContainer.innerHTML = "";
            for (var i = 0; i < files.length; i++) {
                var file = files[i];

                var reader = new FileReader();
                reader.onload = (function (file) {
                    return function (e) {
                        var img = document.createElement("img");
                        img.src = e.target.result;
                        var preview = document.createElement("div");
                        preview.classList.add("preview-container");
                        preview.appendChild(img);
                        previewContainer.appendChild(preview);
                    };
                })(file);
                reader.readAsDataURL(file);
            }
        } else {
            previewContainer.innerHTML = "No Images Selected";
        }

        upload(loc)
    }



    async function upload(loc) {


        var d = new Date();
        var seconds = Math.round(d.getTime() / 1000);

        const storage = getStorage();


        if (loc == 0) {
            var fileInput = document.getElementById("file-input");
        } else {
            var fileInput = document.getElementById("file-input-menu");
        }

        var files = fileInput.files;

        setBanner(true)
        setMessage("Notiek attēlu augšupielāde...")
        setType("process")

        if (files.length > 0 && place) {
            for (var i = 0; i < files.length; i++) {
                var file = files[i];




                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true,
                }
                try {

                    console.log('originalFile instanceof Blob', file instanceof Blob); // true
                    console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

                    const compressedFile = await imageCompression(file, options);
                    console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB


                    if (loc == 0) {
                        var storageRef = ref(storage, `places/${place.id}/interior/${file.name}${seconds}`);
                    } else {
                        var storageRef = ref(storage, `places/${place.id}/menu/${file.name}${seconds}`);
                    }

                    // 'file' comes from the Blob or File API
                    uploadBytes(storageRef, compressedFile).then((snapshot) => {

                        console.log(snapshot)

                        getDownloadURL(snapshot.ref).then(async (downloadURL) => {
                            console.log('File available at', downloadURL);


                            if (loc == 0) {
                                await updateDoc(doc(db, "places", place.id), {
                                    images_interior: arrayUnion({ path: snapshot.metadata.fullPath, token: downloadURL }),
                                    updated_at: seconds
                                }).then(() => {
                                    setBanner(false)
                                })

                            } else {
                                await updateDoc(doc(db, "places", place.id), {
                                    images_menu: arrayUnion({ path: snapshot.metadata.fullPath, token: downloadURL }),
                                    updated_at: seconds
                                }).then(() => {
                                    setBanner(false)
                                })
                            }



                        });

                    }).then(() => {




                    })

                } catch (error) {


                    setBanner(true)
                    setMessage("Vai, kaut kas nogāja greizi...!")
                    setType("error")

                }


            }

        }


    }



    async function deleteImage(item, loc) {
        console.log(item)

        var d = new Date();
        var seconds = Math.round(d.getTime() / 1000);

        setBanner(true)
        setMessage("Notiek attēla dzēšana...")
        setType("process")

        if (loc == 0) {


            const storage = getStorage();

            const imageRef = ref(storage, item.path);

            deleteObject(imageRef).then(async () => {

                await updateDoc(doc(db, "places", place.id), {
                    images_interior: arrayRemove(item),
                    updated_at: seconds
                }).then(() => {
                    setBanner(false)
                })

            }).catch((error) => {

                setBanner(true)
                setMessage("Vai, kaut kas nogāja greizi...!")
                setType("error")

            });


        } else {


            const storage = getStorage();

            const imageRef = ref(storage, item.path);

            deleteObject(imageRef).then(async () => {

                await updateDoc(doc(db, "places", place.id), {
                    images_menu: arrayRemove(item),
                    updated_at: seconds
                }).then(() => {
                    setBanner(false)
                })

            }).catch((error) => {

                setBanner(true)
                setMessage("Vai, kaut kas nogāja greizi...!")
                setType("error")

            });

        }

    }

    return (

        <div className="images">

            <p style={{color: "#C64D54"}}>Pieņemamie attēlu formāti .JPG vai .PNG</p>

            <div className="row">
                <div className="header">
                    <div className="file-button">
                        <input className="file-input" type="file" id="file-input" onChange={() => previewImages(0)} multiple />
                        <label style={{ color: "transparent" }} for="file-input"></label>
                    </div>

                    <h4>Interjiers</h4>

                </div>

                {place &&
                    <div key={place.updated_at} className="uploaded-images">

                        {place && place.images_interior.map((item) => (
                            <div key={item.token} className="image-box">

                                <Delete onClick={() => deleteImage(item, 0)} className="trash" />
                                <img className="upload" src={item.token} />

                            </div>
                        ))}

                    </div>
                }



            </div>



            <div className="row">

                <div className="header">
                    <div className="file-button">
                        <input className="file-input" type="file" id="file-input-menu" onChange={() => previewImages(1)} multiple />
                        <label style={{ color: "transparent" }} for="file-input-menu"></label>
                    </div>

                    <h4>Piedāvājums (Ēdieni / Dzērieni)</h4>

                </div>

                {place &&
                    <div key={place.updated_at} className="uploaded-images">

                        {place && place.images_menu.map((item) => (
                            <div key={item.token} className="image-box">

                                <Delete onClick={() => deleteImage(item, 1)} className="trash" />
                                <img className="upload" src={item.token} />

                            </div>
                        ))}

                    </div>
                }



            </div>

            <div style={{ visibility: "hidden" }} id="preview-container"></div>




            {banner &&
                <Banner message={message} type={type} />
            }

        </div >
    )
}

export default Images;