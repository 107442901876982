import "./Registration.css"
import { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Image from "../../Assets/Humans.svg"
import Logo from "../../Assets/Lilla bizness.svg"

import { auth, db } from '../../Firebase/config';
import { getAuth, fetchSignInMethodsForEmail, linkWithPopup, getRedirectResult, linkWithCredential, signInWithCredential, FacebookAuthProvider, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, signInWithRedirect, linkWithRedirect } from "firebase/auth";
import { collection, setDoc, doc, updateDoc, addDoc, getDoc } from "firebase/firestore";
import { Google, Facebook } from '@mui/icons-material';

import { useNavigate } from "react-router-dom";

function Registration() {

    const navigate = useNavigate();

    const [login, setLogin] = useState("register")

    const [restaurantName, setRestaurantName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState('')

    const [passwordReset, setPasswordReset] = useState(false)
    const [showError, setShowError] = useState("")


    const errorCodes = [
        {
            eng: "Firebase: Error (auth/wrong-password).",
            lv: "Nepareiza parole!"
        },
        {
            eng: "Firebase: Error (auth/user-not-found).",
            lv: "Lietotājs ar šādu e-pastu neeksistē!"
        },
        {
            eng: "Firebase: Error (auth/email-already-in-use).",
            lv: "Lietotājs ar šādu e-pastu jau pastāv!"
        },
        {
            eng: "Firebase: Password should be at least 6 characters (auth/weak-password).",
            lv: "Vāja parole! Parolei jābūt vismaz 6 simbolu garai!"
        },
        {
            eng: "Firebase: Error (auth/invalid-email).",
            lv: "Nepilnīga e-pasta adrese!"
        },
        {
            eng: "Firebase: Error (auth/account-exists-with-different-credential).",
            lv: "Lietotājs ar šādu e-pastu izmanto citu autorizācijas veidu!"
        },
    ]



    const handleSubmit = (e) => {
        e.preventDefault()

        const auth = getAuth();

        switch (login) {

            case "register":
                createUserWithEmailAndPassword(auth, email, password)
                    .then(async (userCredential) => {
                        // Signed in 

                        sendEmailVerification(auth.currentUser)
                            .then(() => {
                                // Email verification sent!
                                // ...
                            });
                        const user = userCredential.user;

                        console.log(user.uid)


                        /*
                                                await addDoc(collection(db, "places"), {
                        
                                                    visibility: false,
                                                    place_name: restaurantName,
                                                    business_name: "",
                                                    vat_number: 0,
                                                    vat_number_prefix: "LV",
                                                    phone_private: phone,
                                                    phone_private_prefix: "+371",
                                                    phone_public: "",
                                                    phone_public_prefix: "+371",
                                                    email_private: "",
                                                    email_public: "",
                                                    working_hours: [{ start: -1, end: -1 }, { start: -1, end: -1 }, { start: -1, end: -1 }, { start: -1, end: -1 }, { start: -1, end: -1 }, { start: -1, end: -1 }, { start: -1, end: -1 }],
                                                    about: "",
                                                    address: "",
                                                    address_id: "",
                                                    address_lat: 0,
                                                    address_long: 0,
                                                    type: [],
                                                    mood: [],
                                                    extras: [],
                                                    updated_at: parseInt(user.metadata.createdAt),
                                                    created_at: parseInt(user.metadata.createdAt),
                                                    images_interior: [],
                                                    images_menu: [],
                                                    images_interior: [],
                                                    food_categories: [],
                                                    drink_categories: [],
                                                    food: [],
                                                    drink: [],
                                                    admins: [user.uid],
                                                    employees: []
                        
                                                }).then(async (item) => {
                        
                                                    await updateDoc(doc(db, "places", item.id), {
                                                        id: item.id
                                                    });
                        
                        
                                                    await setDoc(doc(db, "users", user.uid), {
                                                        id: user.uid,
                                                        email: email,
                                                        created_at: parseInt(user.metadata.createdAt),
                                                        updated_at: parseInt(user.metadata.createdAt),
                                                        places_admin: [item.id]
                                                    })
                                                })
                        */


                        await setDoc(doc(db, "users", user.uid), {
                            id: user.uid,
                            email: email,
                            created_at: parseInt(user.metadata.createdAt),
                            updated_at: parseInt(user.metadata.createdAt),
                            places_admin: []
                        })

                        sessionStorage.setItem('Auth Token', userCredential._tokenResponse.refreshToken)
                        sessionStorage.setItem('UID', user.uid)
                        sessionStorage.setItem('userEmail', user.email)
                        navigate('/about')




                        // ...
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.log(error.message)

                        errorCodes.map((item) => {
                            if (item.eng == errorMessage) {
                                setShowError(item.lv)
                            }
                        })

                        setPassword("")
                    });
                break;

            case "login":
                signInWithEmailAndPassword(auth, email, password)
                    .then((userCredential) => {
                        // Signed in 

                        const user = userCredential.user;
                        console.log(user)
                        sessionStorage.setItem('Auth Token', userCredential._tokenResponse.refreshToken)
                        sessionStorage.setItem('UID', user.uid)
                        sessionStorage.setItem('userEmail', user.email)
                        navigate('/about')
                        // ...
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.log(error.message)

                        errorCodes.map((item) => {
                            if (item.eng == errorMessage) {
                                setShowError(item.lv)
                            }
                        })

                        setPassword("")
                    });
                break;

            case "forgot":

                break;

            default:
                break;

        }







    }



    function forgotPassword() {

        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setEmail("")
                setPasswordReset(true)
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
            });

    }



    function google() {

        let auth = getAuth();

        auth.languageCode = 'lv';
        const provider = new GoogleAuthProvider();


        signInWithPopup(auth, provider)
            .then(async (result) => {
                console.log(result)
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;

                const userRef = doc(db, "users", user.uid);
                const docSnap = await getDoc(userRef);

                if (!docSnap.exists()) {
                    await setDoc(doc(db, "users", user.uid), {
                        id: user.uid,
                        email: user.email,
                        created_at: parseInt(user.metadata.createdAt),
                        updated_at: parseInt(user.metadata.createdAt),
                        places_admin: []
                    })
                }




                console.log(user)
                sessionStorage.setItem('Auth Token', result._tokenResponse.refreshToken)
                sessionStorage.setItem('UID', user.uid)
                sessionStorage.setItem('userEmail', user.email)
                navigate('/about')
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            }).catch((error) => {
                console.log(error)
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });

    }


    function facebook() {

        const auth = getAuth();

        const provider = new FacebookAuthProvider();

        signInWithPopup(auth, provider)
            .then(async (result) => {
                console.log(result)
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = FacebookAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;


                const userRef = doc(db, "users", user.uid);
                const docSnap = await getDoc(userRef);

                if (!docSnap.exists()) {
                    await setDoc(doc(db, "users", user.uid), {
                        id: user.uid,
                        email: user.email,
                        created_at: parseInt(user.metadata.createdAt),
                        updated_at: parseInt(user.metadata.createdAt),
                        places_admin: []
                    })
                }

                console.log(user)
                sessionStorage.setItem('Auth Token', result._tokenResponse.refreshToken)
                sessionStorage.setItem('UID', user.uid)
                sessionStorage.setItem('userEmail', user.email)
                navigate('/about')
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            }).catch((error) => {
                console.log(error)
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.

                console.log(error.customData)
                console.log(email)
                console.log(error.credential)
                console.log(errorMessage)

                errorCodes.map((item) => {
                    if (item.eng == errorMessage) {
                        setShowError(item.lv)
                    }
                })




            });
    }



    function face() {

        const auth = getAuth();

        const provider = new FacebookAuthProvider();

        signInWithPopup(auth, provider)
            .then(async (result) => {


                const user = result.user;


                const userRef = doc(db, "users", user.uid);
                const docSnap = await getDoc(userRef);

                if (!docSnap.exists()) {
                    await setDoc(doc(db, "users", user.uid), {
                        id: user.uid,
                        email: user.email,
                        created_at: parseInt(user.metadata.createdAt),
                        updated_at: parseInt(user.metadata.createdAt),
                        places_admin: []
                    })
                }

                console.log(user)
                sessionStorage.setItem('Auth Token', result._tokenResponse.refreshToken)
                sessionStorage.setItem('UID', user.uid)
                sessionStorage.setItem('userEmail', user.email)
                navigate('/about')



            }).catch((err) => {

                console.log(err)
                console.log(err.code)
                console.log(err.email)
                console.log(err.credential)
                const errorMessage = err.message;

                errorCodes.map((item) => {
                    if (item.eng == errorMessage) {
                        setShowError(item.lv)
                    }
                })


                if (err.code === 'auth/account-exists-with-different-credential') {
                    // The pending Facebook credential.
                    var pendingCred = err.credential;
                    // The provider account's email address.
                    var email = err.email;
                    // Get the sign-in methods for this email.
                    // fetchSignInMethodsForEmail(email).then(methods => {})
                    //console.log(methods)
                    // If the user has several sign-in methods, the first method
                    // in the list will be the "recommended" method to use.
                    /*
                    if (methods[0] === 'password') {
                        // TODO: Ask the user for their password.
                        // In real scenario, you should handle this asynchronously.
                        var password = promptUserForPassword();
                        auth.signInWithEmailAndPassword(email, password).then(result => {
                            return result.user.linkWithCredential(pendingCred);
                        }).then(() => {
                            // Facebook account successfully linked to the existing user.
                            goToApp();
                        });
                        return;
                    }
                    */
                    // All other cases are external providers.
                    // Construct provider object for that provider.
                    // TODO: Implement getProviderForProviderId.
                    // var provider = getProviderForProviderId(methods[0]);









                    /*
                    const provider = new GoogleAuthProvider();

                    signInWithRedirect(auth, provider).then(result => {


                    }).catch((er) => {
                        console.log(er)
                    })
*/




                }

            })


    }



    useEffect(() => {
        const auth = getAuth();
        getRedirectResult(auth).then((result) => {

            console.log(result)
            const user = result.user;

            sessionStorage.setItem('Auth Token', result._tokenResponse.refreshToken)
            sessionStorage.setItem('UID', user.uid)
            sessionStorage.setItem('userEmail', user.email)

            const prov = new FacebookAuthProvider();

            linkWithRedirect(user, prov).then((result) => {
                navigate('/about')
                // ...
            }).catch((error) => {
                console.log(error)
                // Handle Errors here.
                // ...
            });

        }).catch((error) => {
            console.log(error)
        });

    })



    return (

        <div className="registration">

            <Row className="justify-content-md-center" lg={12}>

                {login == "forgot" ?

                    <Col className="left-side">

                        <h3>Paroles maiņa</h3>

                        {passwordReset == true ?

                            <>
                                <div className="about">
                                    <p>Paroles atjaunināšanas links ir nosūtīts uz Jūsu epastu</p>
                                </div>

                                <p onClick={() => { setLogin("login"); setPasswordReset(false) }} style={{ margin: "15px auto", }} className="forgot">Atpakaļ!</p>

                            </>

                            :
                            <>
                                <div className="about">
                                    <p>Ierakstiet epasta adresi uz kuru tiks nosūtīts paroles atjaunināšanas links</p>
                                </div>

                                <form>
                                    <label>
                                        <input
                                            required
                                            type="email"
                                            placeholder="Epasts"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                        />
                                    </label>


                                    <p onClick={() => { setLogin("login"); setPasswordReset(false) }} style={{ margin: "15px auto", }} className="forgot">Atpakaļ!</p>

                                    <h4 onClick={() => forgotPassword()} className='button'>Sūtīt</h4>
                                </form>
                            </>
                        }

                        <div className="info">
                            <p>E-pasts: business@maratolli.com</p>
                            <p>Telefona nr. +371 26677042</p>
                        </div>

                    </Col>

                    :

                    <>

                        {login == "login" ?
                            <Col className="left-side">

                                <h3>Ielogoties</h3>

{/*}
                                <div className="about">
                                    <p>Maratolli ir aplikācija, ar kuras palīdzību ātri, ērti un vienkārši restorāna darbiniekiem pieņemt un apstrādāt klientu pasūtījumus</p>
                                </div>
                    */}

                                <form onSubmit={handleSubmit}>


                                    <label>
                                        <input
                                            required
                                            type="email"
                                            placeholder="Epasts"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                        />
                                    </label>

                                    <label>
                                        <input
                                            required
                                            type="password"
                                            placeholder="Parole"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                        />
                                    </label>

                                    <p onClick={() => { setShowError(""); setLogin("forgot") }} style={{ marginTop: "15px" }} className="forgot">Aizmirsi paroli?</p>


                                    <p style={{ marginTop: "10px", color: "#C64D54" }}>{showError}</p>


                                    <button className='submit-button'>Turpināt</button>


                                    <p>Izmantot citu ielogošanās veidu</p>

                                    <div onClick={() => google()} className="social-button">
                                        <Google />
                                        <p>Izmantot Google profilu</p>
                                    </div>



                                    <div onClick={() => face()} style={{ backgroundColor: "#4267B2" }} className="social-button">
                                        <Facebook />
                                        <p>Izmantot Facebook profilu</p>
                                    </div>




                                    <p style={{ fontSize: "12px" }}>Turpinot, Jūs piekrītat <a href="https://firebasestorage.googleapis.com/v0/b/maratolli-web.appspot.com/o/public_documents%2FMaratolli%20priv%C4%81tuma%20politika.pdf?alt=media&token=ecb7790a-ea5a-4ae4-9113-45c9f712abf2" target="_blank"><span>Maratolli Lietošanas noteikumiem</span></a> un <a href="https://firebasestorage.googleapis.com/v0/b/maratolli-web.appspot.com/o/public_documents%2FT%C4%ABmek%C4%BCa%20vietnes%20lieto%C5%A1anas%20noteikumi.pdf?alt=media&token=50aa5a09-283d-466d-b376-6fafdefee86c" target="_blank"><span>Privātuma politikai</span></a></p>

                                    <p>Neēsi reģistrējies? <span onClick={() => { setShowError(""); setLogin("register") }} style={{ fontWeight: "600", color: "#664B7F", cursor: "pointer" }}>Reģistrēties</span></p>

                                </form>

                                <div className="info">
                                    <p>E-pasts: business@maratolli.com</p>
                                    <p>Telefona nr. +371 26677042</p>
                                </div>


                            </Col>
                            :
                            <Col className="left-side">

                                <h3>Reģistrēties</h3>

{/*}
                                <div className="about">
                                    <p>Maratolli ir aplikācija, ar kuras palīdzību ātri, ērti un vienkārši restorāna darbiniekiem pieņemt un apstrādāt klientu pasūtījumus</p>
                                </div>
                */}

                                <form onSubmit={handleSubmit}>

                                    <label>
                                        <input
                                            required
                                            type="email"
                                            placeholder="Epasts"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                        />
                                    </label>

                                    <label>
                                        <input
                                            required
                                            type="password"
                                            placeholder="Parole"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                        />
                                    </label>

                                    <p style={{ marginTop: "10px", color: "#C64D54" }}>{showError}</p>

                                    <button className='submit-button'>Turpināt</button>


                                    <p>Izmantot citu reģistrācijas veidu</p>

                                    <div onClick={() => google()} className="social-button">
                                        <Google />
                                        <p>Izmantot Google profilu</p>
                                    </div>


                                    <div onClick={() => face()} style={{ backgroundColor: "#4267B2" }} className="social-button">
                                        <Facebook />
                                        <p>Izmantot Facebook profilu</p>
                                    </div>



                                    <p style={{ fontSize: "12px" }}>Turpinot, Jūs piekrītat <a href="https://firebasestorage.googleapis.com/v0/b/maratolli-web.appspot.com/o/public_documents%2FMaratolli%20priv%C4%81tuma%20politika.pdf?alt=media&token=ecb7790a-ea5a-4ae4-9113-45c9f712abf2" target="_blank"><span>Maratolli Lietošanas noteikumiem</span></a> un <a href="https://firebasestorage.googleapis.com/v0/b/maratolli-web.appspot.com/o/public_documents%2FT%C4%ABmek%C4%BCa%20vietnes%20lieto%C5%A1anas%20noteikumi.pdf?alt=media&token=50aa5a09-283d-466d-b376-6fafdefee86c" target="_blank"><span>Privātuma politikai</span></a></p>


                                    <p>Jau reģistrējies? <span onClick={() => { setShowError(""); setLogin("login") }} style={{ fontWeight: "600", color: "#664B7F", cursor: "pointer" }}>Ielogoties</span></p>

                                </form>

                                <div className="info">
                                    <p>E-pasts: business@maratolli.com</p>
                                    <p>Telefona nr. +371 26677042</p>
                                </div>

                            </Col>
                        }

                    </>
                }


                <Col className="right-side">


                    <img className="logo" src={Logo}></img>

                    <div className="about">
                        <h5>Mūsu mērķis</h5>
                        <p>Maratolli komandas mērķis ir izveidot platformu ar kuras palīdzību <span style={{ color: "#7BA87B", fontWeight: "600" }}>savest kopā ēdināšanas iestādes un to klientus</span>, palīdzot palielināt klientu plūsmu un veicinot atpazīstamību sabiedrībā. Tāpat ar platformas palīdzību vēlamies atvieglot klienta izvēli, meklējot sev piemērotāko ēdināšanas iestādi.
                            Platformā plānojam pievienot visas Latvijas teritorijā esošās ēdināšanas iestādes un dot Jums iespēju <span style={{ color: "#7BA87B", fontWeight: "600" }}>bez maksas pievienot un rediģēt</span> visu ar to saistīto informāciju, sākot no adreses un kontaktinformācijas, līdz ēdienkartes un darba laika izmaiņām, kā arī cenām.</p>
                    </div>


                    <img className="humans" src={Image}></img>

                </Col>
            </Row>





        </div >
    )
}

export default Registration;