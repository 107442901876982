import { db } from '../Firebase/config';
import { useState, useEffect, useRef } from "react"
import { collection, query, where, onSnapshot, doc } from "firebase/firestore";

export const useFetchPills = () => {
    const [pills, setPills] = useState(null)


    useEffect(() => {

  


        const unsub = onSnapshot(doc(db, "pills", "pills"), (doc) => {
           setPills(doc.data())
             console.log(doc.data())
        })

        console.log(pills)


        setPills(pills)




        return () => unsub()


    }, [])

    return { pills }
}