import { db } from '../Firebase/config';
import { useState, useEffect, useRef } from "react"
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";

export const useFetchPlaces = (uid) => {
    const [placesArray, setPlaces] = useState(null)


    useEffect(() => {

        var places = [];



        const q = query(collection(db, "places"), where("admins", "array-contains", uid), orderBy("created_at", "desc"));
        const unsub = onSnapshot(q, (querySnapshot) => {

            places = [];

            querySnapshot.forEach((doc) => {

                places.push({ id: doc.id, ...doc.data() })



            })

            console.log("HOP!")
            console.log(places)

        

            setPlaces([])

            setPlaces(placesArray => [...places])

        })




        //console.log(donglesArray)


        return () => unsub()


    }, [uid])

    return { placesArray }
}