import "./Menu.css"

import { useEffect, useState } from "react"
import { AddCircleOutline, CheckCircleOutlineOutlined, Delete, EditOutlined } from '@mui/icons-material';

import { db } from "../../Firebase/config";
import { doc, updateDoc, arrayUnion, arrayRemove, addDoc, collection, deleteDoc } from "firebase/firestore";
import { async } from "@firebase/util";



function Drinks({ place, placeIndex }) {

    const [category, setCategory] = useState("")
    const [name, setName] = useState("")
    const [price, setPrice] = useState("")

    const [sellectedCategory, setSellectedCategory] = useState("")
    const [sellectedItem, setSellectedItem] = useState("")

    const [sellectedCategoryLenght, setSellectedCategoryLength] = useState(0)



    useEffect(()=> {
        setSellectedCategory("")
    }, [placeIndex])


    useEffect(() => {

        if (place) {
            let count = 0;

            place.drink.map((item) => {
                if (item.category == sellectedCategory) {
                    count++
                }

            })

            setSellectedCategoryLength(count)
        }

    }, [place, sellectedCategory])


    async function addCategory() {

        var d = new Date();
        var seconds = Math.round(d.getTime() / 1000);

        if (category != "") {

            await updateDoc(doc(db, "places", place.id), {
                drink_categories: arrayUnion(category),
                updated_at: seconds
            }).then(() => {
                setSellectedCategory(category)
                setCategory("")
            })
        }

    }



    async function addItem() {

        if (name != "" && price != "" && sellectedCategory != "") {


            var d = new Date();
            var seconds = Math.round(d.getTime() / 1000);

            await addDoc(collection(db, "menu"), {
                name: name,
                price: parseFloat(price),
                category: sellectedCategory,
                place_id: place.id,
                isFood: false,
                language: "lv",
                refers_to: "",
                created_at: seconds

            }).then(async (item) => {

                await updateDoc(doc(db, "menu", item.id), {
                    id: item.id,
                    updated_at: seconds
                })



                await updateDoc(doc(db, "places", place.id), {
                    drink: arrayUnion({
                        id: item.id,
                        name: name,
                        price: parseFloat(price),
                        category: sellectedCategory,
                        isFood: false,
                        language: "lv",
                        refers_to: "",
                        created_at: seconds,
                        updated_at: seconds
                    }),

                    updated_at: seconds

                }).then(() => {
                    setName("")
                    setPrice("")
                })

            })

        }

    }


    async function deleteItem(item) {

        if (sellectedItem != "") {

            await deleteDoc(doc(db, "menu", sellectedItem)).then(() => {
                setSellectedItem("")
                setName("")
                setPrice("")
            })


            await updateDoc(doc(db, "places", place.id), {
                drink: arrayRemove(item)
            })

        }
    }


    async function deleteCategory() {

        let found = false

        place.drink.map((item) => {
            if (item.category == sellectedCategory) {
                found = true
            }
        })


        if (!found) {

            await updateDoc(doc(db, "places", place.id), {
                drink_categories: arrayRemove(sellectedCategory)
            }).then(() => {
                setSellectedCategory("")
            })

        }

    }


    async function updateItem() {

        if (sellectedItem != "" && name != "" && price != "") {

            var d = new Date();
            var seconds = Math.round(d.getTime() / 1000);


            let tempArray = place.drink


            tempArray.map((item, index) => {
                if (item.id == sellectedItem) {
                    tempArray[index] = { ...tempArray[index], name: name, price: parseFloat(price), updated_at: seconds }
                }
            })



            await updateDoc(doc(db, "menu", sellectedItem), {
                name: name,
                price: parseFloat(price),
                updated_at: seconds
            }).then(async () => {

                await updateDoc(doc(db, "places", place.id), {
                    drink: tempArray,
                    updated_at: seconds
                }).then(() => {

                    setSellectedItem("")
                    setName("")
                    setPrice("")
                })
            })

        }

    }


    function chooseItem(item) {
        setSellectedItem(item.id)
        setName(item.name)
        setPrice(item.price)
    }


    return (
        <div>
            <div className="menu">


                <div className="left">


                    <div className="text-input">

                        <div style={{ display: "flex" }}>
                            <input
                                placeholder="Kategorija"
                                type="text"
                                onChange={(e) => setCategory(e.target.value)}
                                value={category}
                            />

                            <div onClick={() => addCategory()} className="add-button">
                                <AddCircleOutline className="icon" />
                            </div>

                        </div>
                    </div>


                    <div>
                        {place && place.drink_categories.map((item) => (

                            <>
                                <div style={{ backgroundColor: sellectedCategory == item ? "#D1C9D8" : "" }} onClick={() => setSellectedCategory(item)} className="tab">
                                    <p>{item}</p>

                                    {/*sellectedCategory == item &&
                                    <div>
                                        <EditOutlined className="icon-category" />
                                        <DeleteOutlineOutlined className="icon-category" />
                                    </div>
                    */}
                                </div>

                            </>
                        ))}

                    </div>

                </div>





                <div className="right">


                    <div style={{ display: "flex", visibility: sellectedItem != "" ? "hidden" : "visible" }} className="text-input">


                        <input
                            className="name"
                            type="text"
                            placeholder="Nosaukums"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />

                        <input
                            className="price"
                            placeholder="Cena"
                            type="number"
                            onChange={(e) => setPrice(e.target.value)}
                            value={price}
                        />

                        <div onClick={() => addItem()} className="add-button">
                            <AddCircleOutline className="icon" />
                        </div>

                    </div>


                    <div>
                        {place && place.drink.map((item) => (
                            <>

                                {
                                    item.id == sellectedItem ?

                                        <div style={{ display: "flex" }} className="text-input">
                                            <input
                                                className="name"
                                                type="text"
                                                placeholder="Nosaukums"
                                                onChange={(e) => setName(e.target.value)}
                                                value={name}
                                            />

                                            <input
                                                className="price"
                                                placeholder="Cena"
                                                type="number"
                                                onChange={(e) => setPrice(e.target.value)}
                                                value={price}
                                            />

                                            <div onClick={() => updateItem()} className="edit-button">
                                                <CheckCircleOutlineOutlined className="icon" />
                                            </div>

                                            <div style={{ backgroundColor: "#C64D54" }} onClick={() => deleteItem(item)} className="edit-button">
                                                <Delete className="icon" />
                                            </div>

                                        </div>
                                        :
                                        <div>
                                            {item.category == sellectedCategory &&
                                                <div onClick={() => chooseItem(item)} className="tab">
                                                    <p>{item.name}</p>
                                                    <p>{item.price} €</p>
                                                </div>
                                            }
                                        </div>
                                }
                            </>

                        ))}

                    </div>

                </div>



            </div>


            {sellectedCategory != "" &&
                <>

                    {sellectedCategoryLenght == 0 && <Delete onClick={(() => deleteCategory())} style={{ fontSize: "35px" }} className="icon-category" />}
                </>
            }

        </div>
    )
}

export default Drinks