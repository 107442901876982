import "./Banner.css"

function Banner(props) {

    return (
        <div style={{backgroundColor: props.type == "error" ? "#C64D54" : "#664B7F"}} className="banner"> 
            <h6>{props.message}</h6>
        </div>
    )
}

export default Banner;