import './Appbar.css'
import { useEffect, useState } from "react";
import { getAuth, signOut } from "firebase/auth";
import { Menu, Logout } from '@mui/icons-material';
import { AddCircleOutline, ArrowDropDown } from '@mui/icons-material';
import { NavLink } from "react-router-dom";


import { db } from '../Firebase/config';
import { collection, setDoc, doc, updateDoc, addDoc, arrayUnion, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";



function Appbar({ places, setPlaceIndex, placeIndex }) {

    const [isPhone, setIsPhone] = useState(false)
    const [burger, setBurger] = useState(false)
    const [x, setX] = useState(false)

    const [name, setName] = useState('')

    const [dropdown, setDropdown] = useState(false)



    useEffect(() => {
        if (window.screen.width <= 1000) {
            console.log(window.screen.width)
            setIsPhone(true)
            setBurger(true)
        } else {
            setIsPhone(false)
            setBurger(false)
            openNav()
        }
    }, [window.screen.width])






    function openNav() {
        document.getElementById("mySidenav").style.width = "250px";
        if (isPhone) {
            setBurger(false)
            setX(true)
        }
    }

    /* Set the width of the side navigation to 0 */
    function closeNav() {

        if (isPhone) {
            document.getElementById("mySidenav").style.width = "0";

            setX(false)


            setBurger(true)
        }
    }



    function logout() {

        const auth = getAuth();
        signOut(auth).then(() => {

            sessionStorage.removeItem('Auth Token');
            sessionStorage.removeItem('UID');

            window.location.reload()

        }).catch((error) => {

        });

    }



    useEffect(() => {

        // Get the modal
        var modal = document.getElementById("modal");

        // Get the button that opens the modal
        var btn = document.getElementById("modal-button");

        // Get the <span> element that closes the modal
        var cBtn = document.getElementsByClassName("cancel-button")[0];

        // When the user clicks the button, open the modal 
        btn.onclick = function () {
            modal.style.display = "block";
        }

        // When the user clicks on <span> (x), close the modal
        cBtn.onclick = function () {
            modal.style.display = "none";
            setName("")
        }

        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function (event) {
            if (event.target == modal) {
                modal.style.display = "none";
                setName("")
            }
        }
    })





    async function createPlace() {

        //maratolliaccesscode-8eep6Kwi99Eewg3EyPkG
        if (name != "") {


            if (!name.includes("maratolliaccesscode-")) {


                let UID = sessionStorage.getItem('UID')
                let registration_email = sessionStorage.getItem('userEmail')

                var d = new Date();
                var seconds = Math.round(d.getTime() / 1000);

                var modal = document.getElementById("modal");
                modal.style.display = "none";



                await addDoc(collection(db, "places"), {

                    visibility: false,
                    place_name: name,
                    business_name: "",
                    vat_number: 0,
                    vat_number_prefix: "LV",
                    phone_private: "",
                    phone_private_prefix: "+371",
                    phone_public: "",
                    phone_public_prefix: "+371",
                    email_private: "",
                    email_public: "",
                    working_hours: [{ start: -1, end: -1 }, { start: -1, end: -1 }, { start: -1, end: -1 }, { start: -1, end: -1 }, { start: -1, end: -1 }, { start: -1, end: -1 }, { start: -1, end: -1 }],
                    about: "",
                    address: "",
                    address_id: "",
                    address_lat: 0,
                    address_long: 0,
                    type: [],
                    mood: [],
                    extras: [],
                    updated_at: seconds,
                    created_at: seconds,
                    images_interior: [],
                    images_menu: [],
                    images_interior: [],
                    food_categories: [],
                    drink_categories: [],
                    food: [],
                    drink: [],
                    admins: UID != "yrf8nnEulKhlXOAo19b3C1mHN7K2" ? [UID, "yrf8nnEulKhlXOAo19b3C1mHN7K2"] : [UID],
                    employees: [],
                    registration_email: registration_email

                }).then(async (item) => {

                    setName("")

                    await updateDoc(doc(db, "places", item.id), {
                        id: item.id
                    }).then(async () => {

                        await updateDoc(doc(db, "users", UID), {
                            updated_at: seconds,
                            places_admin: arrayUnion(item.id)
                        }).then(() => {
                            setPlaceIndex(0)
                            // window.location.reload()
                        })

                    })

                })

            } else {

                let id = name.replace('maratolliaccesscode-', '');
                setName("Uzgaidiet brīdi...")

                let UID = sessionStorage.getItem('UID')


                const functions = getFunctions();
                const addPlaceById = httpsCallable(functions, 'addPlaceById');
                addPlaceById({ placeId: id, uid: UID })
                    .then((result) => {

                        setName("")
                        var modal = document.getElementById("modal");
                        modal.style.display = "none";
                    });


            }



        }

    }





    return (

        <div className='appbar' style={{
            float: isPhone ? 'none' : 'left',
            position: isPhone ? 'fixed' : 'none',
        }}>


            <div id="modal" class="modal">

                <div class="modal-content">

                    <div className="text-input">
                        <p>
                            Iestādes nosaukums
                        </p>

                        <input
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                    </div>


                    <div style={{ display: "flex", margin: "0 auto" }}>
                        <div style={{ backgroundColor: name.length != 0 ? "#7BA87B" : "" }} onClick={() => createPlace()} className='create-button'>
                            <p>Izveidot</p>
                        </div>

                        <div className='cancel-button'>
                            <p>Atcelt</p>
                        </div>
                    </div>

                </div>

            </div>



            <div id="mySidenav" className="sidenav" >
                {isPhone && x ? <a href="javascript:void(0)" class="closebtn" onClick={() => closeNav()}>&times;</a> : <></>}


                <div id="modal-button" className='add-place'>
                    <AddCircleOutline />
                    <p>Pievienot iestādi</p>
                </div>


                {sessionStorage.getItem('userEmail') == "gabriels22paulins@gmail.com" ?

                    <>

                        {places && places.map((item, index) => (
                            <div className='sellected-place'>
                                {index == placeIndex &&
                                    <div onClick={() => setDropdown(!dropdown)} className='dropdown-button'>
                                        <p>{item.place_name}</p>
                                        <ArrowDropDown />
                                    </div>}
                            </div>
                        ))}


                        {dropdown &&
                            <div>
                                {places && places.map((item, index) => (
                                    <div className={index == placeIndex ? "sellected-place" : "place"}>
                                        {item.place_name != "" ?
                                            < p onClick={() => { setPlaceIndex(index); closeNav(); setDropdown(!dropdown) }} >{item.place_name}</p>
                                            :
                                            <p onClick={() => { setPlaceIndex(index); closeNav(); setDropdown(!dropdown) }} >[Nosaukums...]</p>
                                        }

                                        {item.visibility == true ?
                                            <label>Publisks</label>
                                            :
                                            <label>Privāts</label>
                                        }
                                    </div>
                                ))}
                            </div>
                        }



                    </>


                    :
                    <>
                        {places && places.map((item, index) => (
                            <div className={index == placeIndex ? "sellected-place" : "place"}>
                                {item.place_name != "" ?
                                    < p onClick={() => { setPlaceIndex(index); closeNav() }} >{item.place_name}</p>
                                    :
                                    <p onClick={() => { setPlaceIndex(index); closeNav() }} >[Nosaukums...]</p>
                                }

                                {item.visibility == true ?
                                    <label>Publisks</label>
                                    :
                                    <label>Privāts</label>
                                }
                            </div>
                        ))}

                    </>
                }

                <div className='line'>

                </div>

                <NavLink
                    onClick={() => closeNav()}
                    to="/about"
                    className={({ isActive }) =>
                        isActive ? "sellected" : undefined
                    }
                >
                    Par mums
                </NavLink>

                <NavLink
                    onClick={() => closeNav()}
                    to="/images"
                    className={({ isActive }) =>
                        isActive ? "sellected" : undefined
                    }
                >
                    Attēli
                </NavLink>

                <NavLink
                    onClick={() => closeNav()}
                    to="/food"
                    className={({ isActive }) =>
                        isActive ? "sellected" : undefined
                    }
                >
                    Ēdienkarte
                </NavLink>

                <NavLink
                    onClick={() => closeNav()}
                    to="/drinks"
                    className={({ isActive }) =>
                        isActive ? "sellected" : undefined
                    }
                >
                    Dzērienkarte
                </NavLink>


                {!burger && <div onClick={() => logout()} className='logout'>
                    <Logout />
                    <h5>Iziet</h5>
                </div>
                }

            </div>

            {burger ? <Menu className='menu-button' onClick={() => openNav()} /> : <></>}


        </div >


    )
}

export default Appbar;