import './App.css';
import { useEffect, useState, useCallback } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Registration from './Pages/Registration/Registration';
import About from './Pages/About/About';
import Images from './Pages/Images/Images'
import Appbar from './Appbar/Appbar';

import { useFetchPlaces } from './Hooks/useFetchPlaces';

import {
  Routes,
  Route,
  useNavigate
} from "react-router-dom";

import Food from './Pages/Menu/Food';
import Drinks from './Pages/Menu/Drinks';

function App() {

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [isPhone, setIsPhone] = useState(false)

  const [place, setPlace] = useState()
  const [placeIndex, setPlaceIndex] = useState(0)


  const { placesArray } = useFetchPlaces(sessionStorage.getItem('UID'))



  let navigate = useNavigate();


  useEffect(() => {

    let authToken = sessionStorage.getItem('Auth Token')

    if (authToken) {
      navigate('/about')
    }

    if (!authToken) {
      navigate('/')
    }
  }, [])



  useEffect(() => {
    if (window.screen.width < 1000) {

      setIsPhone(true)

    } else {
      setIsPhone(false)
    }
  }, [])





  return (

    <div className="App">



      <Routes>
        <Route path='/' element={<Registration />} />


        <Route path='/about' element={
          <>
            {placesArray && <Appbar places={placesArray} placeIndex={placeIndex} setPlaceIndex={setPlaceIndex} />}
            <div style={{ marginLeft: isPhone ? '0' : "250px" }}>
              {placesArray && placesArray.length != 0 && <About key={placesArray.length} placeIndex={placeIndex} setPlaceIndex={setPlaceIndex} placesArray={placesArray} />}
            </div>
          </>
        } />


        <Route path='/images' element={
          <>
            {placesArray && <Appbar places={placesArray} placeIndex={placeIndex} setPlaceIndex={setPlaceIndex} />}
            <div style={{ marginLeft: isPhone ? '0' : "250px" }}>
              {placesArray && placesArray.length != 0 && <Images key={placesArray.length} place={placesArray[placeIndex]} />}
            </div>
          </>
        } />


        <Route path='/food' element={
          <>
             {placesArray && <Appbar places={placesArray} placeIndex={placeIndex} setPlaceIndex={setPlaceIndex} />}
            <div style={{ marginLeft: isPhone ? '0' : "250px" }}>
              {placesArray && placesArray.length != 0 && <Food key={placesArray.length} placeIndex={placeIndex} place={placesArray[placeIndex]} />}
            </div>
          </>
        } />


        <Route path='/drinks' element={
          <>
            {placesArray && <Appbar places={placesArray} placeIndex={placeIndex} setPlaceIndex={setPlaceIndex} />}
            <div style={{ marginLeft: isPhone ? '0' : "250px" }}>
              {placesArray && placesArray.length != 0 && <Drinks key={placesArray.length} placeIndex={placeIndex} place={placesArray[placeIndex]} />}
            </div>
          </>
        } />


      </Routes>


    </div>

  );
}

export default App;
