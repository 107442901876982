import "./Menu.css"

import { useEffect, useState } from "react"
import { AddCircleOutline, CheckCircleOutlineOutlined, Delete, EditOutlined } from '@mui/icons-material';

import { db } from "../../Firebase/config";
import { doc, updateDoc, arrayUnion, arrayRemove, addDoc, collection, deleteDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";





function Food({ place, placeIndex }) {

    const [category, setCategory] = useState("")
    const [name, setName] = useState("")
    const [price, setPrice] = useState("")

    const [sellectedCategory, setSellectedCategory] = useState("")
    const [sellectedItem, setSellectedItem] = useState("")

    const [sellectedCategoryLenght, setSellectedCategoryLength] = useState(0)




    useEffect(() => {
        setSellectedCategory("")
    }, [placeIndex])

    useEffect(() => {

        if (place) {
            let count = 0;

            place.food.map((item) => {
                if (item.category == sellectedCategory) {
                    count++
                }

            })

            setSellectedCategoryLength(count)
        }

    }, [place, sellectedCategory])


    async function addCategory() {

        var d = new Date();
        var seconds = Math.round(d.getTime() / 1000);

        if (category != "") {

            await updateDoc(doc(db, "places", place.id), {
                food_categories: arrayUnion(category),
                updated_at: seconds
            }).then(() => {
                setSellectedCategory(category)
                setCategory("")
            })
        }

    }



    async function addItem() {

        if (name != "" && price != "" && sellectedCategory != "") {

            console.log("WORKS")

            var d = new Date();
            var seconds = Math.round(d.getTime() / 1000);

            await addDoc(collection(db, "menu"), {
                name: name,
                price: parseFloat(price),
                category: sellectedCategory,
                place_id: place.id,
                isFood: true,
                language: "lv",
                refers_to: "",
                created_at: seconds

            }).then(async (item) => {

                await updateDoc(doc(db, "menu", item.id), {
                    id: item.id,
                    updated_at: seconds
                })



                await updateDoc(doc(db, "places", place.id), {
                    food: arrayUnion({
                        id: item.id,
                        name: name,
                        price: parseFloat(price),
                        category: sellectedCategory,
                        isFood: true,
                        language: "lv",
                        refers_to: "",
                        created_at: seconds,
                        updated_at: seconds
                    }),

                    updated_at: seconds

                }).then(() => {
                    setName("")
                    setPrice("")
                })

            })

        }

    }


    async function deleteItem(item) {

        if (sellectedItem != "") {

            await deleteDoc(doc(db, "menu", sellectedItem)).then(() => {
                setSellectedItem("")
                setName("")
                setPrice("")
            })


            await updateDoc(doc(db, "places", place.id), {
                food: arrayRemove(item)
            })

        }
    }


    async function deleteCategory() {

        let found = false

        place.food.map((item) => {
            if (item.category == sellectedCategory) {
                found = true
            }
        })


        if (!found) {

            await updateDoc(doc(db, "places", place.id), {
                food_categories: arrayRemove(sellectedCategory)
            }).then(() => {
                setSellectedCategory("")
            })

        }

    }


    async function updateItem() {

        if (sellectedItem != "" && name != "" && price != "") {

            var d = new Date();
            var seconds = Math.round(d.getTime() / 1000);


            let tempArray = place.food

            console.log(tempArray)

            tempArray.map((item, index) => {
                if (item.id == sellectedItem) {
                    tempArray[index] = { ...tempArray[index], name: name, price: parseFloat(price), updated_at: seconds }
                }
            })

            console.log(tempArray)



            await updateDoc(doc(db, "menu", sellectedItem), {
                name: name,
                price: parseFloat(price),
                updated_at: seconds
            }).then(async () => {

                await updateDoc(doc(db, "places", place.id), {
                    food: tempArray,
                    updated_at: seconds
                }).then(() => {

                    setSellectedItem("")
                    setName("")
                    setPrice("")
                })
            })

        }

    }


    function chooseItem(item) {
        setSellectedItem(item.id)
        setName(item.name)
        setPrice(item.price)
    }






    async function upload() {

        console.log("UPLOAD")
        const storage = getStorage();

        var fileInput = document.getElementById("file-input");

        var files = fileInput.files;


        if (files.length > 0 && place) {
            for (var i = 0; i < files.length; i++) {
                var file = files[i];


                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true,
                }
                try {

                    console.log('originalFile instanceof Blob', file instanceof Blob); // true
                    console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

                    /*
                    const compressedFile = await imageCompression(file, options);
                    console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
*/

                    var storageRef = ref(storage, `places/${place.id}/menu/food/${file.name}`);

                    // 'file' comes from the Blob or File API
                    uploadBytes(storageRef, file).then((snapshot) => {

                        console.log(snapshot)

                        getDownloadURL(snapshot.ref).then(async (downloadURL) => {
                            console.log('File available at', downloadURL);

                            var d = new Date();
                            var seconds = Math.round(d.getTime() / 1000);


                            await updateDoc(doc(db, "places", place.id), {
                                menu_document: arrayUnion({ path: snapshot.metadata.fullPath, token: downloadURL, name: file.name }),
                                updated_at: seconds
                            });

                        });

                    }).then(() => {


                    })

                } catch (error) {
                    console.log(error);
                }


            }


            {/*}
            console.log(document.getElementById("file-input").files)

           document.getElementById("file-input").value = ""

           console.log(document.getElementById("file-input").files)
        */}

        }


    }

    async function deleteImage(item) {

        var d = new Date();
        var seconds = Math.round(d.getTime() / 1000);


        const storage = getStorage();

        const imageRef = ref(storage, item.path);

        deleteObject(imageRef).then(async () => {

            await updateDoc(doc(db, "places", place.id), {
                menu_document: arrayRemove(item),
                updated_at: seconds
            })

        }).catch((error) => {
            // Uh-oh, an error occurred!
        });


    }


    return (
        <div>
            <div className="menu">


                <div className="left">


                    <div className="text-input">

                        <div style={{ display: "flex" }}>
                            <input
                                placeholder="Kategorija"
                                type="text"
                                onChange={(e) => setCategory(e.target.value)}
                                value={category}
                            />

                            <div onClick={() => addCategory()} className="add-button">
                                <AddCircleOutline className="icon" />
                            </div>

                        </div>
                    </div>


                    <div>
                        {place && place.food_categories.map((item) => (

                            <>
                                <div style={{ backgroundColor: sellectedCategory == item ? "#D1C9D8" : "" }} onClick={() => setSellectedCategory(item)} className="tab">
                                    <p>{item}</p>

                                    {/*sellectedCategory == item &&
                                    <div>
                                        <EditOutlined className="icon-category" />
                                        <DeleteOutlineOutlined className="icon-category" />
                                    </div>
                    */}
                                </div>

                            </>
                        ))}

                    </div>

                </div>





                <div className="right">


                    <div style={{ display: "flex", visibility: sellectedItem != "" ? "hidden" : "visible" }} className="text-input">


                        <input
                            className="name"
                            type="text"
                            placeholder="Nosaukums"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />

                        <input
                            className="price"
                            placeholder="Cena"
                            type="number"
                            onChange={(e) => setPrice(e.target.value)}
                            value={price}
                        />

                        <div onClick={() => addItem()} className="add-button">
                            <AddCircleOutline className="icon" />
                        </div>

                    </div>


                    <div>
                        {place && place.food.map((item) => (
                            <>

                                {
                                    item.id == sellectedItem ?

                                        <div style={{ display: "flex" }} className="text-input">
                                            <input
                                                className="name"
                                                type="text"
                                                placeholder="Nosaukums"
                                                onChange={(e) => setName(e.target.value)}
                                                value={name}
                                            />

                                            <input
                                                className="price"
                                                placeholder="Cena"
                                                type="number"
                                                onChange={(e) => setPrice(e.target.value)}
                                                value={price}
                                            />

                                            <div onClick={() => updateItem()} className="edit-button">
                                                <CheckCircleOutlineOutlined className="icon" />
                                            </div>

                                            <div style={{ backgroundColor: "#C64D54" }} onClick={() => deleteItem(item)} className="edit-button">
                                                <Delete className="icon" />
                                            </div>

                                        </div>
                                        :
                                        <div>
                                            {item.category == sellectedCategory &&
                                                <div onClick={() => chooseItem(item)} className="tab">
                                                    <p>{item.name}</p>
                                                    <p>{item.price} €</p>
                                                </div>
                                            }
                                        </div>
                                }
                            </>

                        ))}

                    </div>

                </div>



            </div>






            {sellectedCategory != "" &&
                <>

                    {sellectedCategoryLenght == 0 && <Delete onClick={(() => deleteCategory())} style={{ fontSize: "35px" }} className="icon-category" />}
                </>
            }




            {place &&
                <div key={place.updated_at} className="uploaded-files">

                    <p>Ja nevēlaties manuāli ievadīt ēdienkarti/dzērienkarti, tad droši varat pievienot failus un mūsu komanda ievadīs tos Jūsu vietā</p>

                    <div className="file-button">
                        <input className="file-input" type="file" id="file-input" onChange={() => upload()} multiple />
                        <label style={{ color: "transparent" }} for="file-input"></label>
                    </div>

                    {place && place.menu_document && place.menu_document.map((item) => (
                        <div key={item.token} className="file-box">
                            <a target="_blank" href={item.token}>{item.name}</a>
                            <Delete onClick={() => deleteImage(item, 0)} className="icon-category" />
                        </div>
                    ))}

                </div>
            }

        </div>
    )
}

export default Food